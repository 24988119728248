<div class="position-absolute start-0 end-0 start-0 bottom-0 w-100 h-100">
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 800 800">
    <g fill-opacity="0.22">
      <circle style="fill: rgba(var(--ct-primary-rgb), 0.1);" cx="400" cy="400" r="600"></circle>
      <circle style="fill: rgba(var(--ct-primary-rgb), 0.2);" cx="400" cy="400" r="500"></circle>
      <circle style="fill: rgba(var(--ct-primary-rgb), 0.3);" cx="400" cy="400" r="300"></circle>
      <circle style="fill: rgba(var(--ct-primary-rgb), 0.4);" cx="400" cy="400" r="200"></circle>
      <circle style="fill: rgba(var(--ct-primary-rgb), 0.5);" cx="400" cy="400" r="100"></circle>
    </g>
  </svg>
</div>
<div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xxl-4 col-lg-5" *ngIf="!actions.resetPassword">
        <div class="card">

          <!-- Logo -->
          <div class="card-header py-4 text-center bg-primary">
            <a href="index.html">
              <span><img src="assets/images/logo.png" alt="logo" height="82"></span>
            </a>
          </div>

          <div class="card-body p-4">

            <div class="text-center w-75 m-auto">
              <h4 class="text-dark-50 text-center pb-0 fw-bold">Sign In</h4>
              <p class="text-muted mb-4">Enter your email address and password to access admin panel.</p>
            </div>

            <form [formGroup]="loginForm" (submit)="validateForm()" class="needs-validation" novalidate>

              <div class="mb-3">
                <label for="emailaddress" class="form-label">Email address</label>
                <input class="form-control" type="email" id="emailaddress" required="" placeholder="Enter your email" formControlName="username">
                <div class="invalid-feedback">
                  Please enter a valid email.
                </div>
              </div>

              <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <a class="text-muted float-right" (click)="openCloseResetPasswordForm()"><small>Forgot your
                    password?</small></a>
                <div class="input-group input-group-merge">
                  <input type="password" id="password" class="form-control" placeholder="Enter your password" formControlName="password">
                  <div class="invalid-feedback">
                    Please enter a password.
                  </div>
                </div>
              </div>
              <div class="mb-3 mb-0 text-center">
                <button class="btn btn-primary" type="submit"> Log In </button>
              </div>

            </form>
            <div class="col-12 text-center">
              <p class="text-muted">Don't have an account? <a [routerLink]="['/sign-up']"
                  routerLinkActive="router-link-active" class="text-muted ms-1"><b>Sign Up</b></a></p>
            </div> <!-- end col -->
          </div> <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="row mt-3">
          
        </div>
        <!-- end row -->

      </div> <!-- end col -->

      <div class="col-xxl-4 col-lg-5" *ngIf="actions.resetPassword">
        <div class="card">

          <!-- Logo -->
          <div class="card-header py-4 text-center bg-primary">
            <a href="index.html">
              <span><img src="assets/images/logo.png" alt="logo" height="82"></span>
            </a>
          </div>

          <div class="card-body p-4">

            <div class="text-center w-75 m-auto">
              <h4 class="text-dark-50 text-center pb-0 fw-bold">Reset password</h4>
              <p class="text-muted mb-4">Enter your email address in order to reset your password.</p>
            </div>

            <form [formGroup]="loginForm" class="needs-validation" novalidate>

              <div class="mb-3">
                <label for="emailaddress" class="form-label">Email address</label>
                <input class="form-control" type="email" id="emailaddress" required="" placeholder="Enter your email"
                  formControlName="username">
              </div>
              <div class="mb-3 mb-0 text-center">
                <button class="btn btn-primary" (click)="resetPassword()"> Send reset password email
                </button>
              </div>

            </form>
          </div> <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-muted">Don't have an account? <a [routerLink]="['/sign-up']"
                routerLinkActive="router-link-active" class="text-muted ms-1"><b>Sign Up</b></a></p>
          </div> <!-- end col -->
        </div>
        <!-- end row -->

      </div> <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
  <footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <script>document.write(new Date().getFullYear())</script>2024 © Equestrian Digital
            </div>
            <div class="col-md-6">
              <span class="my-auto"><small>v{{ version }}</small></span>
            </div>
            <!-- <div class="col-md-6">
                <div class="text-md-end footer-links d-none d-md-block">
                    <a href="javascript: void(0);">About</a>
                    <a href="javascript: void(0);">Support</a>
                    <a href="javascript: void(0);">Contact Us</a>
                </div>
            </div> -->
        </div>
    </div>
</footer>
</div>
